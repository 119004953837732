.app {
    min-width: 100%;
    height: 100vh;
    /* width */
    overflow-y: auto;
    scrollbar-color: #008bcb transparent;
    scrollbar-width: thin !important;

    &::-webkit-scrollbar-corner {
        background: transparent
    }

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 8px
    }

    /* Track */
    &::-webkit-scrollbar-track:horizontal {
        box-shadow: inset 0 0 5px transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb:horizontal {
        background: grey;
        border-radius: 5px;

    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:horizontal:hover {
        background: grey;
    }

    /* Track */
    &::-webkit-scrollbar-track:vertical {
        box-shadow: inset 0 0 5px transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb:vertical {
        background: #11729f;
        border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:vertical:hover {
        background: #0a97be;
    }
}

.app-shell-main {
    padding-left: 5px;
    padding-right: 5px;
}